<template>
  <div>
    <h2>Create Project</h2>
    <div class="sub-mt-2">
      <!-- input name and path -->
      <b-form-input v-model="path" placeholder="Path"></b-form-input>
      <b-form-input v-model="name" placeholder="Name"></b-form-input>
      <b-form-input v-model="slug" placeholder="Slug"></b-form-input>
      <button class="btn btn-primary" @click="createProject">Create Project</button>
    </div>
    <h2>Push New Version</h2>
    <div class="sub-mt-2">
      <!-- selector project -->
      <b-select v-model="selectedProject" @change="selectProject">
        <b-select-option v-for="project in projects" :key="project.id" :value="project">{{ project.name
          }}</b-select-option>
      </b-select>
      <div>Path: {{ selectedProject?.path }}</div>
      <div>Latest Patch: {{ lastPatchNumber }}</div>
      <b-checkbox v-model="doNotRunCommand">Do Not Run Command</b-checkbox>
      <!-- displays the path -->
      <div class="form-inline sub-mr-2">
        <button class="btn btn-primary" @click="pushVersion">Push Version</button>
        <button class="btn btn-primary" @click="pushPatch">Push Patch</button>
      </div>
      <div class="form-inline sub-mr-2">
        <button class="btn btn-primary" @click="notifyPushedVersion">Notify Pushed Version</button>
        <button class="btn btn-primary" @click="notifyPushedVersion(false)">Notify Pushed Patch</button>
      </div>
    </div>
    <h2>App Config</h2>
    <div class="form-inline sub-mr-2">
      <div>Version</div>
      <b-form-input v-model="appConfigVersion" placeholder="0.1.0" />
      <button class="btn btn-primary" @click="newAppConfigVersion">New Version</button>
    </div>
    <h2>Engines</h2>
    <div class="sub-mt-2">
      <b-form-input v-model="engine.name" placeholder="Name / Version"></b-form-input>
      <b-form-input v-model="engine.storagePath" placeholder="Path on GCS"></b-form-input>
      <button class="btn btn-primary" @click="addEngine">Create Engine</button>
    </div>
    <h2>Debug</h2>
    <div class="sub-mt-2">
      <button class="btn btn-primary" @click="testOutput">Test Output</button>
    </div>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('sget-ops');
import { db } from '@/services/db';
import { deleteCollection } from '@/services/dbutils';
import axios from 'axios';

export default {
  data() {
    return {
      projects: [],
      selectedProject: null,
      selectedProjectPatches: [],

      name: '',
      slug: '',
      path: '',

      engine: {},

      appConfigVersion: "0.1.0",

      doNotRunCommand: false,
    };
  },
  computed: {
    lastPatchNumber() {
      return this.selectedProjectPatches.length;
    },
  },
  async mounted() {
    await this.$bind("projects", db.collection('ShivaGetProjects'));
    if (this.projects.length) {
      this.selectedProject = this.projects[0];
    }
  },
  methods: {
    createProject() {
      log.log('createProject', this.name, this.slug, this.path);
      db.collection('ShivaGetProjects').doc(this.slug).set({
        path: this.path,
        name: this.name,
        slug: this.slug,
      });
      this.selectedProject = {
        path: this.path,
        name: this.name,
        slug: this.slug,
      };
    },
    async selectProject() {
      await this.$bind("selectedProjectPatches", db.collection(`ShivaGetProjects/${this.selectedProject.id}/versions/1/patches`));
    },
    pushVersion() {
      log.log('pushVersion');
      db.collection(`ShivaGetProjects/${this.selectedProject.id}/versions`).doc('1').set({
        version: '1',
        date: new Date(),
      }, { merge: true });
      if (!this.doNotRunCommand) {
        this.$emit("run-command", "ShivaPush.bat", [
          this.selectedProject.slug,
          this.selectedProject.path,
        ], { windowsVerbatimArguments: true }, (errorCode) => {
          if (!errorCode)
            this.notifyPushedVersion();
        });
      }
      deleteCollection(db.collection(`ShivaGetProjects/${this.selectedProject.id}/versions/1/patches`));
    },
    notifyPushedVersion(isMajor = true) {
      log.log('notifyPushedVersion');
      let version = isMajor ? 'Major' : 'Minor (Patch)';
      // calls discord webhook with axios
      axios.post('https://discord.com/api/webhooks/1229874833341550703/jYMf1VstN0HEhZBrVi0LE4MPCSUFN2l5piiroBrwCyQHjAsijn9cmCz0pgX9lRcms9mb', {
        username: "ShivaGet",
        avatar_url: "https://pfp.nostr.build/4a5ba08c251bfd0822355a91c5065005933b3469dc869bb5f1afbd1023970eb8.png",
        content: `**${this.selectedProject.name}** - New ${version} Version Pushed by ${this.$store.account.displayName}`,
      });
    },
    pushPatch() {
      let patchNumber = this.lastPatchNumber + 1;
      log.log('pushPatch', patchNumber);
      db.collection(`ShivaGetProjects/${this.selectedProject.id}/versions/1/patches`).doc(`${patchNumber}`).set({
        version: '1',
        patch: patchNumber,
        date: new Date(),
      });
      if (!this.doNotRunCommand) {
        this.$emit("run-command", "ShivaPushPatch.bat", [
          this.selectedProject.slug,
          this.selectedProject.path,
          patchNumber,
        ], { windowsVerbatimArguments: true }, (errorCode) => {
          if (!errorCode)
            this.notifyPushedVersion(false);
        });
      }
    },
    newAppConfigVersion() {
      log.log('newAppConfigVersion', this.appConfigVersion);
      db.collection('ShivaAppConfigs').doc(this.appConfigVersion).set({
        date: new Date(),
        allShopsUnlocked: false,
        disableBuild: false,
        menuShowJoin: true,
        showSearchServer: true,
      });
    },
    addEngine() {
      log.log('addEngine', this.engine);
      db.collection('ShivaGetEngines').doc(this.engine.name).set(this.engine);
    },
    testOutput() {
      this.$emit("run-command", "ShivaGetTestOutput.bat", [
        "warning:",
        "test",
      ], { windowsVerbatimArguments: true });
    }
  },
};
</script>

<style scoped>
pre {
  white-space: pre-wrap;
}
</style>